

















import {
  computed,
  defineComponent,
} from '@nuxtjs/composition-api';
import {usePageBuilder} from "~/composables/Palmers";

export default defineComponent({
  name: 'ElementButton',
  props: {
    element: {
      type: Object,
      require: true
    }
  },
  setup(props) {
    const {getLink, appendCustomCss} = usePageBuilder();

    const link = computed(() => getLink(props.element.link))

    return {
      link,
      appendCustomCss
    }
  },
  methods: {
    getButtonStyles() {
      const styles = {};

      if(this.element.button_background_color) {
        styles['background'] = this.element.button_background_color;
      }

      if(this.element.button_border_color) {
        styles['border-color'] = this.element.button_border_color;
      }

      if(this.element.button_border_radius) {
        styles['border-radius'] = `${this.element.button_border_radius}px`;
      }

      if(this.element.button_border_style) {
        styles['border-style'] = this.element.button_border_style;
      }

      if(this.element.button_border_width) {
        styles['border-width'] = `${this.element.button_border_width}px`;
      }

      if(this.element.button_color) {
        styles['color'] = this.element.button_color;
      }

      if(this.element.full_width) {
        styles['width'] = '100%';
      }

      if(this.element.button_hover_background_color) {
        styles['--button_hover_background_color'] = this.element.button_hover_background_color;
      }

      if(this.element.button_hover_border_color) {
        styles['--button_hover_border_color'] = this.element.button_hover_border_color;
      }

      if(this.element.button_hover_color) {
        styles['--button_hover_color'] = this.element.button_hover_color;
      }

      return styles;
    }
  },
  mounted() {
    if(this.element.button_css) {
      this.appendCustomCss(this.element.button_css)
    }
  }
});
